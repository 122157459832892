import React from 'react';
import SignupCover from 'views/SignupCover';
import Minimal from 'layouts/Minimal';
import WithLayout from 'WithLayout';

const SignupCoverPage = (): JSX.Element => {
	return <WithLayout component={SignupCover} layout={Minimal} />;
};

export default SignupCoverPage;
